import { getIsDebug } from '@grandstand-web/bally-web-shared/src/hooks/useIsDebug'
import { ApiClient } from '@grandstand-web/bally-web-shared/src/newPackages/ApiClient'
import {
  ConfigServiceContext,
  useContextUnconditionally,
} from '@grandstand-web/bally-web-shared/src/services/config/ConfigService'
import { UserServiceContext } from '@grandstand-web/bally-web-shared/src/services/user/UserService'
import { ErrorDetails, ErrorButton as VideoErrorButton } from '@grandstand-web/bally-web-shared/src/type/Error'
import { isConnectedWeb } from '@grandstand-web/bally-web-shared/src/utils/appUtils'
import { getWindowDeviceInfo } from '@grandstand-web/bally-web-shared/src/utils/getWindowDeviceInfo'
import { Logger } from '@grandstand-web/bally-web-shared/src/utils/logger'
import { TDeviceInfo } from '@grandstand-web/device-info/src/types'
import { Link, Video } from '@grandstand/presentation-models'
import { SourceConfig } from 'bitmovin-player'
import { NextRouter } from 'next/router'
import { Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from 'react'
import { PlayerType } from '../types'
import { getAppVersion } from '../utils/deviceInfoUtils'
import { getBitmovinDRM } from '../utils/getBitmovinDRM'
import { getKeySystems } from '../utils/getKeySystems'

export type ConvivaVideoAnalytics = {
  [key: string]: any
}

export type VideoAnalytics = {
  default: Analytics
  conviva: ConvivaVideoAnalytics
}

export interface VideoSrc {
  videoId: string
  title: string
  src: string
  type: 'application/dash+xml' | 'application/x-mpegURL'
  isLive: boolean
  stream_type: 'linear' | 'vod'
  keySystems?: KeySystems
  heartbeat: Heartbeat
  analytics: Analytics
  conviva_analytics?: {
    [key: string]: any
  }
  raw: VideoResponse
}

export interface Heartbeat {
  interval: number
  link: Link
}

// All props are marked as optional since the payload may change depending
// on video type.
export interface VideoAnalyticsForGoogle {
  away_team?: string
  broadcast_team?: string
  content_region?: string
  content_type?: string
  first_air_date?: string
  first_air_time?: string
  first_air_timestamp?: string
  game_id?: string
  game_type?: string
  home_team?: string
  league?: string
  material_id?: string
  stream_type?: string
  streaming_method?: string
  team?: string
  video_duration?: number
  video_id?: string
  video_platform?: string
  video_title?: string
  video_type?: string
}

export interface Analytics extends VideoAnalyticsForGoogle {
  /* */
}

export interface HeartbeatResponse {
  message: string
}

export interface KeySystems {
  'com.widevine.alpha'?: DRMInfo
  'com.microsoft.playready'?: DRMInfo
  'com.apple.fps.1_0'?: any
}

export interface DRMInfo {
  url?: string
  licenseHeaders?: LicenseHeaders
}

export interface FairplayDRMInfo {
  licenseUri?: string
  certificateUri: string
  licenseHeaders: LicenseHeaders
  certificateHeaders: LicenseHeaders
}

export interface LicenseHeaders {
  'x-dt-auth-token'?: string
}

export interface VideoResponse {
  url: string
  title: string
  description: string
  islive: boolean
  stream_type: 'linear' | 'vod'
  drm?: DRM
  heart_beat: Heartbeat
  analytics: Analytics
  conviva_analytics?: {
    [key: string]: any
  }
  start_date: string
  end_date: string
  next_id?: string
  video: Video
}

// ERROR types + classes
export type ButtonSize = 'min' | 'xs' | 'sm' | 'md' | 'lg'
export type ButtonStyle = 'primary' | 'alt' | 'on-image' | 'live'

// .video types

export type VideoState = {
  title: string
  url: string
  src: VideoSrc
  isLive: boolean
  keySystems?: KeySystems
  heartbeat?: Heartbeat
}
// .video DRM types
export interface DRM {
  licenseUrl: string
  token: string
}

export interface KeySystems {
  'com.widevine.alpha'?: DRMInfo
  'com.microsoft.playready'?: DRMInfo
  'com.apple.fps.1_0'?: any
}

export interface DRMInfo {
  url?: string
  licenseHeaders?: LicenseHeaders
}

export interface FairplayDRMInfo {
  licenseUri?: string
  certificateUri: string
  licenseHeaders: LicenseHeaders
  certificateHeaders: LicenseHeaders
}

export interface LicenseHeaders {
  'x-dt-auth-token'?: string
}

// heartbeat
export interface Heartbeat {
  interval: number
  link: Link
}

export interface HeartbeatResponse {
  message: string
}

export class VideoServiceError {
  status: number
  message: string
  code: string
  constructor({ message, status, code }: { message: string; status: number; code: string }) {
    this.message = message
    this.status = status
    this.code = code
  }
}

// Reducer for VideoService.state
export type DataStatus = 'loading' | 'success' | 'error'
export type DRMType = 'widevine' | 'playready' | 'fairplay'
export type DRMFormat = 'DASH' | 'HLS'
export const isVideoSrc = (src: VideoSrc | SourceConfig | null): src is VideoSrc => {
  return src && typeof (src as any)?.keySystems !== 'undefined' ? true : false
}

export const isSourceConfig = (src: VideoSrc | SourceConfig | null): src is SourceConfig => {
  return src && typeof (src as any)?.keySystems === 'undefined' ? true : false
}
export type VideoServiceProps = {
  videoId: string
  useRouter?: () => NextRouter
  isDisabled?: boolean
  playerType: PlayerType
}
export type VideoService = {
  data: VideoResponse | null
  playerType: PlayerType
  src: VideoSrc | null
  sourceConfig: SourceConfig | null
  analytics: VideoAnalytics
  error: ErrorDetails | null
  setError: Dispatch<SetStateAction<ErrorDetails | null>>
  status: DataStatus
}
export const useVideoService = ({ videoId, isDisabled, playerType }: VideoServiceProps): VideoService => {
  Logger.of('useVideoService').info('init', { videoId, isDisabled, playerType })

  const { currentConfig } = useContextUnconditionally(ConfigServiceContext)
  const { currentUser } = useContext(UserServiceContext)
  const [src, setSrc] = useState<VideoSrc | null>(null)
  const [sourceConfig, setSourceConfig] = useState<SourceConfig | null>(null)
  const [analytics, setAnalytics] = useState<VideoAnalytics>({ default: {}, conviva: {} })
  const [error, setError] = useState<ErrorDetails | null>(null)
  const [data, setData] = useState<VideoResponse | null>(null)
  const [deviceInfo, setDeviceInfo] = useState<TDeviceInfo>(getWindowDeviceInfo())
  const adInfo = deviceInfo.adInfo
  const isSafari = deviceInfo.browser === 'safari'
  const drmInfo = deviceInfo?.drmInfo

  const status = useMemo<DataStatus>(() => {
    if (error) {
      return 'error'
    } else {
      return src ? 'success' : 'loading'
    }
  }, [error, src])

  // keep deviceInfo up to date
  useEffect(() => {
    const updateDeviceInfo = () => {
      setDeviceInfo(getWindowDeviceInfo())
    }

    updateDeviceInfo()
    if (typeof window === 'undefined') {
      return
    }
    window.addEventListener('resize', updateDeviceInfo)
    return () => {
      window.removeEventListener('resize', updateDeviceInfo)
    }
  }, [])

  useEffect(() => {
    if (error || typeof window === 'undefined' || !drmInfo) {
      return
    }
    const getVideo = async () => {
      Logger.of('useVideoService.getVideo').info('Getting video', { videoId, deviceInfo })

      const configUrl = currentConfig?.API.services.video_services.playback
      const token = currentUser?.user_token

      if (configUrl === undefined || token === undefined || !drmInfo || !adInfo) {
        return
      }

      const getUrl = (): string => {
        const baseUrl = `${configUrl}/${videoId}`
        const params: { [key: string]: any } = {
          drmType: drmInfo.type,
          format: drmInfo.format,
          appversion: getAppVersion(),
          debug: getIsDebug(),
          ...deviceInfo.adInfo,
        }

        const favTeams = currentUser?.profile.favorites.teams

        if (favTeams && favTeams.length > 0) {
          params.favteams = favTeams.join(',')
        }

        if (isConnectedWeb()) {
          // this controls the length of live streams' dvr windows
          // if true: the player will only load 2 min of dvr window
          // else: the player will load the full dvr window (which can be up to 4 hours, slowing down load times)
          params.chromecast = true
        }
        if (params.did === '') {
          delete params.did
        }

        // convert params to string
        const paramsString = new URLSearchParams(params).toString()

        const url = `${baseUrl}?${paramsString}`
        Logger.of('useVideoService.getVideo').debug('params', { params, paramsString, url, videoId })
        return url
      }
      const url = getUrl()
      try {
        const res = await ApiClient.convenientApiFetch({
          url,
          method: 'GET',
        })

        if (res.ok) {
          const data = (await res.json()) as VideoResponse
          const {
            video: { title, description, thumbnail },
          } = data
          // formats are DASH or hls_v3
          if (playerType === 'shaka') {
            const keySystems = await getKeySystems(videoId, data)
            // formats are DASH or hls_v3
            const src: VideoSrc = {
              videoId,
              raw: data,
              title: title,
              type: isSafari ? 'application/x-mpegURL' : 'application/dash+xml',
              src: data.url,
              keySystems: keySystems,
              heartbeat: data.heart_beat,
              analytics: data.analytics,
              conviva_analytics: data.conviva_analytics,
              isLive: data.islive,
              stream_type: data.stream_type,
            }
            Logger.of('useVideoService.getVideo').debug('Setting video src', { videoId, playerType, src })
            setSrc(src)
          } else {
            const drm = await getBitmovinDRM(data)
            const sourceConfig: SourceConfig = {
              [isSafari ? 'hls' : 'dash']: data.url,
              drm,
              poster: thumbnail,
              title,
              description,
            }

            setSourceConfig(sourceConfig)
            Logger.of('useVideoService.getVideo').debug('Setting video sourceConfig', {
              videoId,
              playerType,
              sourceConfig,
            })
          }
          setAnalytics({
            default: data?.analytics ?? {},
            conviva: data?.conviva_analytics ?? {},
          })
          setData(data)
          setError(null)
        } else {
          const error = await res.json()
          Logger.of('useVideoService.getVideo').error('Video service error', { videoId, error })
          setError(error)
          setSrc(null)
        }
      } catch (error) {
        Logger.of('useVideoService.getVideo').error('Video fetch error', { error, url, videoId })
        setError({ status: 0, code: 'network_error', title: 'network error', message: 'network error', severity: 0 })
      }
    }

    // get video
    getVideo()
    return () => {
      /* */
    }
  }, [
    playerType,
    adInfo,
    currentConfig?.API.services.video_services.playback,
    currentUser?.profile.favorites.teams,
    currentUser?.user_token,
    deviceInfo,
    deviceInfo.adInfo,
    drmInfo,
    error,
    isSafari,
    videoId,
  ])

  useEffect(() => {
    const heartbeat = data?.heart_beat
    if (error || !heartbeat || isDisabled) {
      return () => {
        /* */
      }
    }
    const url = heartbeat.link.url

    const getHeartbeat = async () => {
      if (error) {
        return
      }
      try {
        const res = await ApiClient.convenientApiFetch({
          method: 'POST',
          url,
        })
        if (res.ok) {
          // Heartbeat successful!
          return
        }
        const error = await res.json()
        Logger.of('useVideoService.getHeartbeat').error('Video service error', { videoId, error })
        setError({
          code: 'unknown_heartbeat_error', // will be overwritten if code present
          ...error,
        })
        return
      } catch (e) {
        console.error(e)
      }
    }

    getHeartbeat()

    // get heartbeat every ms
    const ms = heartbeat?.interval ?? 500
    const heartbeatInterval = setInterval(() => {
      getHeartbeat()
    }, ms)
    return () => {
      clearInterval(heartbeatInterval)
    }
  }, [error, videoId, data?.heart_beat, isDisabled])

  return {
    data,
    playerType,
    src,
    sourceConfig,
    analytics,
    error,
    setError,
    status,
  }
}
export { ErrorDetails, VideoErrorButton }
