import { Logger } from '@grandstand-web/bally-web-shared/src/utils/logger'
import { KeySystems, VideoResponse } from '../hooks/useVideoService'
import { modifyDRMTokenIfApplicable } from './modifyDRMTokenIfApplicable'
export const getKeySystems = async (videoId: string, body: VideoResponse): Promise<KeySystems> => {
  if (body?.drm === undefined) {
    Logger.of('useVideoService.getVideo').error('drm not found', { videoId })
    return {}
  }
  const drm = body.drm
  drm.token = await modifyDRMTokenIfApplicable(`${drm.token}`)
  var headers = {
    'x-dt-auth-token': drm.token,
  }
  return {
    'com.widevine.alpha': {
      url: drm.licenseUrl,
      licenseHeaders: headers,
    },
    'com.apple.fps.1_0': {
      getContentId: function (_emeOptions: any, initData: any) {
        return initData
      },
      getCertificate: async function (_emeOptions: any, callback: (e: any, c: any) => void) {
        let url = new URL(drm.licenseUrl)
        url.pathname += 'cert/ballysports'
        const res = await fetch(url.toString(), {
          method: 'GET',
          headers: {
            ...headers,
          },
        })
        const responseBody = await res.arrayBuffer()
        callback(null, new Uint8Array(responseBody))
      },
      getLicense: async function (
        _emeOptions: any,
        _contentId: any,
        keyMessage: any,
        callback: (e: any, c: any) => void
      ) {
        const res = await fetch(drm.licenseUrl, {
          method: 'POST',
          body: keyMessage,
          headers: {
            ...headers,
          },
        })
        const responseBody = await res.text()
        let rawLicenseString = atob(responseBody)
        let data = new Uint8Array(rawLicenseString.length)
        for (let i = 0; i < rawLicenseString.length; ++i) {
          data[i] = rawLicenseString.charCodeAt(i)
        }
        let key = data.buffer
        callback(null, key)
      },
    },
  }
}
