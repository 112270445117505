import { Logger } from '@grandstand-web/bally-web-shared/src/utils/logger'
import getConfig from 'next/config'
import { useEffect, useState } from 'react'
import { PlayerType } from '../types'

export const usePlayerType = () => {
  const [playerType, setPlayerType] = useState(getPlayerType())
  useEffect(() => {
    try {
      const playerType = getPlayerType()
      setPlayerType(playerType)
    } catch (error) {
      Logger.of('usePlayerType').warn('Error in usePlayerType', error)
    }
  }, [])
  return playerType
}

export function getPlayerType(): PlayerType {
  let playerType: PlayerType = 'bitmovin'
  const { publicRuntimeConfig } = getConfig()
  if (typeof publicRuntimeConfig?.playerType === 'string') {
    playerType = publicRuntimeConfig.playerType === 'shaka' ? 'shaka' : 'bitmovin'
  }
  return playerType
}
