// combine the two interfaces
import { usePlayerType } from '../hooks/usePlayerType'
import { TPlayerProps } from '../types'
import VideoErrorView from './VideoErrorView'
import { VideoLoading } from './VideoLoading'
import VideoLoadingOrBuffering from './VideoLoadingOrBuffering'
import VideoPlayerWrapper from './VideoPlayerWrapper'
import VideoTitle from './VideoTitle'

type PlayerComponent = React.FC<TPlayerProps> & {
  LoadingOrBuffering: typeof VideoLoadingOrBuffering
  Wrapper: typeof VideoPlayerWrapper
  Loading: typeof VideoLoading
  ErrorView: typeof VideoErrorView
  Title: typeof VideoTitle
}
const Player: PlayerComponent = (props: TPlayerProps) => {
  const playerType = usePlayerType()
  const Player = playerType === 'shaka' ? require('./PlayerShaka').default : require('./PlayerBitmovin').default
  return <Player {...props} playerType={playerType} />
}
Player.LoadingOrBuffering = VideoLoadingOrBuffering
Player.Wrapper = VideoPlayerWrapper
Player.Loading = VideoLoading
Player.ErrorView = VideoErrorView
Player.Title = VideoTitle

export { Player as default, Player }
