import { clientRuntimeConfig } from '@grandstand-web/bally-web-shared/src/config'
import { TDeviceInfo, TWindowWithDeviceInfo } from '@grandstand-web/device-info/src/types'
import getConfig from 'next/config'

export const getWindowDeviceInfo = (): TDeviceInfo => {
  return typeof window !== 'undefined' ? (window as any as TWindowWithDeviceInfo).deviceInfo : {}
}

const getValidString = (str: any): string | undefined => {
  return typeof str === 'string' && str.length > 0 ? str : undefined
}
export const getAppVersion = (ignoreNative?: boolean): string => {
  const { publicRuntimeConfig } = getConfig()
  const rootVersion =
    getValidString(clientRuntimeConfig?.rootVersion) ?? getValidString(publicRuntimeConfig?.version) ?? 'undefined'

  if (ignoreNative) {
    return rootVersion
  }

  return getWindowDeviceInfo()?.nativeVersion ?? rootVersion
}
