import dynamic from 'next/dynamic'
import { VideoLoading } from '../VideoLoading'
import './style/index.scss'

const PlayerBitmovin = dynamic(() => import('./PlayerBitmovinStatic'), {
  ssr: false,
  loading: () => <VideoLoading isBuffering={true} />,
})

export default PlayerBitmovin
