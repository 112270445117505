import { getWindowDeviceInfo } from '@grandstand-web/bally-web-shared/src/utils/getWindowDeviceInfo'
import { AppleFairplayDRMConfig, DRMConfig, HttpHeaders, WidevineModularDRMConfig } from 'bitmovin-player'
import { VideoResponse } from '../hooks/useVideoService'
import { modifyDRMTokenIfApplicable } from './modifyDRMTokenIfApplicable'

export const getBitmovinDRM = async (data: VideoResponse): Promise<DRMConfig> => {
  const drm = data.drm
  const device = getWindowDeviceInfo()
  if (!drm) {
    return {}
  }
  const { licenseUrl, token: originalToken } = drm
  const token = await modifyDRMTokenIfApplicable(originalToken)
  const LA_URL = licenseUrl
  const headers: HttpHeaders = {
    'x-dt-auth-token': token,
  }
  const getPlayReadyDRM = (): WidevineModularDRMConfig => {
    return {
      LA_URL,
      headers: {
        ...headers,
        'Content-Type': 'text/xml',
      },
      retryOtherKeysOnForbiddenLicense: true,
      keySystemPriority: ['com.microsoft.playready'],
      withCredentials: true,
    }
  }

  const getWidevineDRM = (): WidevineModularDRMConfig => {
    return {
      LA_URL,
      headers,
      keySystemPriority: ['com.widevine.alpha'],
      withCredentials: true,
    }
  }

  // broken on safari - need to fix
  const getFairplayDRM = (): AppleFairplayDRMConfig => {
    let certUrl = new URL(drm.licenseUrl)
    certUrl.pathname += 'cert/ballysports'

    return {
      LA_URL,
      certificateURL: certUrl.toString(),
      headers,
      certificateHeaders: headers,
      keySystemPriority: ['com.apple.fps.1_0', 'com.apple.fps'],
      useUint16InitData: true,
      withCredentials: true,
      maxCertificateRequestRetries: 3,
      maxLicenseRequestRetries: 3,
      prepareMessage: (event, session) => {
        return 'spc=' + encodeURIComponent(event.messageBase64Encoded) + '&' + session.contentId
      },
      prepareContentId: (contentId) => {
        const pattern = 'skd://drmtoday?'
        let parameters
        let idx = contentId.indexOf(pattern)
        if (idx > -1) {
          parameters = contentId.substring(idx + pattern.length)
          parameters = parameters.replace(/assetid/gi, 'assetId')
          parameters = parameters.replace(/variantid/gi, 'variantId')
          return parameters
        } else {
          return contentId
        }
      },
    }
  }

  switch (device.platform) {
    case 'tv_samsung':
      return {
        widevine: getWidevineDRM(),
      }
    case 'tv_xboxone':
      return {
        playready: getPlayReadyDRM(),
      }
  }
  if (device.browser === 'safari') {
    return {
      fairplay: getFairplayDRM(),
    }
  }

  return {
    widevine: getWidevineDRM(),
  }
}
